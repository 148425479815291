import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesNavModule } from './extensions/d7bbadeadd4a61425667f0d9c83bb6fd64e7a9ac1f5b7336cdf5926e510b0aa3/badge-nav.module';

import SharedProviders_1_0 from './extensions/product-importer-ui/providers';


@NgModule({
    imports: [CommonModule, BadgesNavModule],
    providers: [...SharedProviders_1_0],
})
export class SharedExtensionsModule {}
